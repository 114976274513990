body{
    padding: 0;
    margin: 0;
}
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none; 
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}