@import "../css-config/mixins.scss";

.breadcrumb {
    width: 100%;
    margin-top: 20px;
    position: sticky;
    top: 40px;
    z-index: 100;
    // background-color: #FCFCFF;
    // border: 2px solid gray;
    display: flex;
    align-items: center;
    // padding-left: 2px;
    padding-right: 10px;
    margin-left: -5px;

    @include lg {
        top: 48px;
    }

    .breadcrumb-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px;
        padding-left: 5px;

        Button {

            @include lg {
                height: 60px !important;
                width: 65px;
                padding: 0px;
                marginBottom: 0px;
                marginTop: 0px;



            }

            @include xl {
                height: 60px;
                width: 95px;
                padding: 0px;
                marginBottom: 0px;
                marginTop: 0px;
                // background-color: aquamarine;



            }

            @include xxl {
                height: 70px;
                width: 120px;
                padding: 0px;
                marginBottom: 0px;
                marginTop: 0px;


            }

        }



        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background-color: transparent;
        }

        .breadcrumb-btn1 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 3px;
            text-align: center;
            padding: 1px;


            img {
                @include lg {

                    height: 14px;
                    width: 10px;
                    padding: 1px;

                }

                @include xl {
                    height: 20px;
                    width: 20px;
                    padding: 1px;


                }

                @include xxl {
                    height: 30px;
                    width: 30px;
                    padding: 1px;
                }
            }

            // border: 1px solid black;

            .breadcrumb-btn1Text {
                margin-left: 5px;
                text-transform: capitalize;
                display: flex;
                flex-direction: column;

                text-align: left;

                padding: 0px;
                align-items: center;
                font-size: 11px;
                line-height: 17px;
                font-weight: 600;
                // border: 1px solid red;

                font-style: normal;


                @include lg {
                    // margin-left: 5px!important;
                    font-size: 6px !important;
                    line-height: 10px !important;
                    font-weight: 0px;
                }

                @include xl {
                    font-size: 9px !important;
                    line-height: 10px !important;
                    // font-weight: 0px; 
                    line-height: 15px !important;
                    // font-weight: 700 !important;
                }

                @include xxl {
                    // font-size: 18px!important;
                    line-height: 15px !important;
                    // font-weight: 700px; 
                }
            }


            .icon-img {
                transition: color 0.3s ease-in-out;
            }

            .icon-img:hover {
                color: white;
            }
        }

        .doted {
            color: #979797;
            display: flex;
            justify-content: flex-start;

            // background-color: beige;
            // border: 0.1px dashed #979797;
        }

        .doted:after {
            // flex: 1;
            border-bottom: 1px dotted #979797;
            content: '';
            flex: 1;
        }

    }


}

// @media screen and (max-width: 1250px) {
//     .breadcrumb-btn1Text {
//         margin-left: 2px !important;
//         text-transform: capitalize;
//         display: flex;
//         flex-direction: column;
//         text-align: left;

//         padding: 0px;
//         align-items: center;
//         font-size: 10!important;
//         line-height: 17px;
//         // font-weight: 500;
//         color: rgb(255, 115, 0);
//         // border: 1px solid red;

//         font-style: normal;
//     }
// }