@import "../../css-config/mixins.scss";

.HeaderSidebar-container {
    width: 100%;

    position: "relative";
    width: "100%";
    border: '1px solid red';
    box-sizing: 'border-box';

    .HeaderSidebar {
        // background: var(--color-neutral-00, #FFF);
        box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.07);
        padding: 10px 44px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 2px solid #EBEBEB;

        .logo {
            width: 140.8px;
            height: 51.2px;

            @include xxl {
                width: 176px;
                height: 64px;
            }
        }

        .search-user {
            display: flex;
            align-items: center;
            gap: 24px;

            @include xxl {
                gap: 48px;
            }
        }
        .text-style {
            font-size: 15px;
            // font-weight: 600;
        }


    }
}



// @media (max-width:425px) {
//     .HeaderSidebar-container {
//         width: 100%;
//         border:'1px solid red';
//     }
// }


@media (min-width: 200px) and (max-width:700px) {


    .HeaderSidebar-container {

        .text-style {
            font-size: 10px;
        }

    }
}

@media (min-width: 200px) and (max-width:700px) {


    .search-user {

        .search-bar {
            display: none;
        }

    }
}