.main-sign {
  padding: 1rem 2rem;

  .sign-in {
    display: flex;
    flex-direction: column;
  }
}

.css-cbmgv2-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #1A4277;
}

@media only screen and (min-width:200px) and (max-width:600px) {
  .main-sign {
    padding: 0;
    text-align: center !important;
  }
}