@import "../../css-config/mixins.scss";

.SearchBar-Container {
    background: #F8F9FA;
    border-radius: 5px;
    border-bottom: 2px solid #EBEBEB;
    padding: 8px 18px 8px 18px;

    .searchInputs {
        display: flex;
        align-items: center;

        input {
            @include lg {
                display: none;
            }

            background-color: transparent;
            border: none;
            color: #AEB5BC;
        }

        SearchIcon {
            @include lg {
                height: 20px;
                width: 20px;
            }
            @include sm {
                height: 15px;
                width: 15px;
            }
        }
    }
}