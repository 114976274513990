.subNav {

    .nav-item {
        .sub-element {
            flex-direction: row-reverse;
            padding: 5px 25px;
            display: flex;
            justify-content: left;
            gap: 20px;
            align-items: center;
            color: black;
            text-decoration: none;
        
            
            &:hover {
                color: white;
            }

            .link{
                text-decoration: none;
                padding: 0px 20px 0px 0px;
                color: black;
                
                
            }
            .link1{
                color: white;
                padding: 0px 20px 0px 0px;

                text-decoration: none;
            }
                
                
                
        }
    }
    margin:0;
    padding: 0;

    li {
        list-style: none;
        


        .subMenu-container {


            position: relative;
            top: 15px;
            left: 0px;
            


            .nav-item {
                .sub-element {

                    flex-direction: row;
                    padding: 5px 25px;
                    display: flex;
                    align-items: center;
                    // color: black;
                    justify-content: space-between;
                    
                    &:hover {
                        color: white;
                    }


                }
            }
        }
    }
}

.Navbar .subMenu-container:nth-child(2) .subNav {
    li {

        padding: 10px 0px;
    
     
        
    }
}

.subNav-div {

    background-color: white;
    border-bottom: none;
    box-shadow: none;

    
    &:hover {
        background-color: #02A0FC;
        color: white;
    }
    
        
}
        
    



                

                











                







        


