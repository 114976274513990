@import "../../css-config/mixins.scss";

.main-servicerNavBar {

  flex-direction: column;
  height: 100%;
 
  // border: 2px solid red;
  border-right: 2px solid F3F6FD;
  box-shadow: #F3F6FD;

  .main-contianer-servicerNavBar {
    display: flex;
    // justify-content: center;
    align-items: flex-start;
    justify-content: flex-start;
    // height: 100%;
    border-right: 2px solid F3F6FD;
    padding: 0;
    margin: 0;
    // background-color: #F3F6FD;
    z-index: 10000000;
    .serviceAvenger-icon-image {
      height: 90px;
      width: 90px;
    }

  }
}

.servicer-name {
  padding: 1rem 0rem 0rem 0.5rem;
  display: flex;
  justify-content: start;
  // border: 1px solid red;
}

.servicer-name-button {
  // border: 1px solid red;

}
.close_icon{
  display: none !important;
}
@media (min-width: 200px) and (max-width:600px) {
  .main-contianer-servicerNavBar{
    display: block !important;
  }
  .close_icon{
    display: flex !important;
  }
  .serviceAvenger-icon-image {
    height: 180px;
    width: 180px;
  }
  .servicer-name-button {
    //   width: 2rem;
    //  margin-left: 0.2rem;
    .servicer-navbar-title {
      display: inline;
      text-transform: none;
      // border: #0f1219;
    }
  }
  .main-contianer-servicerNavBar{
    min-width:80vw;
    background:#F3F6FD;
    min-height:100vh;
  }
}

@media (min-width: 600px) and (max-width:767px) {
  .servicer-navbar-title {
  
    text-transform: none;
    display: none !important;
  }
}

@media (min-width: 200px) and (max-width:800px) {

  .main-servicerNavBar {
    // display: none;
  }

  .main-contianer-servicerNavBar{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .servicer-name {
    padding: 1.2rem 1rem;
    display: flex;
    justify-content: flex-start;
    // border: 1px solid red;
  }

  // .servicer-name-button {

  //   //   width: 2rem;
  //   //  margin-left: 0.2rem;
  //   .servicer-navbar-title {
  //     display: none;
  //     // border: #0f1219;
  //   }
  // }
  .servicer-name-button{
      width:100% !important;
  }



}

// @media (max-width:426px) {
//   .main-contianer-servicerNavBar{
//   .main-container-avatar{
//     border: '1px solid red';
//     height:"70px"; 
//      width:"70px";

//     img.serviceAvenger-icon-image{
//       height:"70px"; 
//       width:"70px";
//     }

// }
//   }
// }

@media only screen and (min-width: 200px) and (max-width: 800px) {
  .main-container-avatar {
    .image-container {
      // img.serviceAvenger-icon-image {
      //   height: 60px;
      //   width: 65px;
      // }
    }
  }
}