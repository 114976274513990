@import "../../css-config/mixins.scss";

.Header-Container {
    width: 100%;

    .Header {
        background: var(--color-neutral-00, #FFF);
        box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.07);
        padding: 10px 44px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 140.8px;
            height: 51.2px;

            @include xxl {
                width: 176px;
                height: 64px;
            }
        }

        .Search-User {
            display: flex;
            align-items: center;
            gap: 24px;

            @include xxl {
                gap: 48px;
            }
        }
    }
}