.main-login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  .login-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.main-box {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 50%;
  box-shadow: 3 !important;
  // margin-left: 15rem;
  
  .main-box-content {

    display: flex;
    // justify-content: center;
  }
}

.mh-support-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-login-button {
  text-transform: none;
  background-color: #1A4277 !important;
  // border: 1px solid red !important ;
}

// .css-k123u5-MuiButtonBase-root-MuiButton-root {
//   background-color: #1A4277 !important;
//   border: 1px solid red;
// }
// .microsoft-sign-in {
//   text-transform: none;
//   color: #1A4277;
//   border: solid 1px #1A4277;
// }

.login_box_shadow_responsive{
  padding: 34px 0px 50px 0px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.221),
                0 6px 10px rgba(0, 0, 0, 0.15);
}
@media only screen and (min-width:200px) and (max-width:"812px"){
  .box_shadow_responsive{
    box-shadow: none ;

  }
  
}