@mixin xs {
  @media only screen and (min-width: 100px) and (max-width: 600px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (min-width: 600px) and (max-width: 900px) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: 900px) and (max-width: 1050px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: 1050px) and (max-width: 1200px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: 600px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}